import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { ReactElement } from "react"
import { Image } from "../../types/images"
import Footer from "./Footer"
import Layout from "./layout"
import Seo from "./Seo"
import WorkImage from "./WorkImage"

interface Props {
  children: JSX.Element | JSX.Element[]
  title: string
  images: Image[]
}

const WorkDetail = ({ children, title, images }: Props): ReactElement => {
  return (
    <Layout>
      <Seo title={title} />
      <div className="work-detail">
        <div className="container">
          <div className="lg:w-8/12 mx-auto">
            <AnchorLink
              to="/#work"
              className="text-red flex items-center font-bold text-sm uppercase mb-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="70"
                overflow="visible"
                viewBox="0 0 100 70"
                className="fill-current h-3 w-auto mr-1"
              >
                <path d="M40 70c-1.2 0-2.3-.4-3.3-1.2l-35-30C.6 37.8 0 36.5 0 35s.6-2.8 1.7-3.8l35-30c2.1-1.8 5.3-1.6 7.1.5 1.8 2.1 1.6 5.3-.5 7.1L12.7 35l30.6 26.2c2.1 1.8 2.3 5 .5 7.1-1 1.1-2.4 1.7-3.8 1.7z" />
                <path d="M95 40H5c-2.8 0-5-2.2-5-5s2.2-5 5-5h90c2.8 0 5 2.2 5 5s-2.2 5-5 5z" />
              </svg>
              Work
            </AnchorLink>
            <h1 className="heading text-3xl md:text-6xl text-charcoal">
              {title}
            </h1>
            <div className="content mt-8">{children}</div>
          </div>
        </div>
        <div className="work-img-container">
          {images.map(img => {
            return <WorkImage img={img} key={img.id} />
          })}
        </div>

        <Footer />
      </div>
    </Layout>
  )
}
export default WorkDetail

import React, { ReactElement } from "react"
import { Image } from "../../../types/images"
import MoreLink from "../../components/MoreLink"
import WorkDetail from "../../components/WorkDetail"

const imgs: Image[] = [
  {
    src: "ChatMail/wireframe-grid_zylbtc.jpg",
    alt: "Wireframes",
    id: "1",
    chrome: false,
  },
  {
    src: "ChatMail/solutions_ug793g.jpg",
    alt: "Find a Store",
    id: "2",
    chrome: true,
  },
  {
    src: "ChatMail/features-cropped_qyoihn.jpg",
    alt: "Features",
    id: "3",
    chrome: true,
  },
  {
    src: "ChatMail/screenshot_pbkcek.jpg",
    alt: "Find a Store",
    id: "4",
    chrome: true,
  },
]

export default function Chatmail(): ReactElement {
  return (
    <WorkDetail title="ChatMail" images={imgs}>
      <p className="lead">
        ChatMail was developed to give businesses, individuals and organizations
        the ability to share information freely, safely and efficiently.
      </p>

      <p>
        As a company that is very serious about security, ChatMail&apos;s online
        presence needs to reflect that. My role was to make sure this came
        across in the design of their new website in addition to making sure
        users are able to easily discover the features ChatMail offers.
      </p>

      <MoreLink to="https://chatmailsecure.com/" />
    </WorkDetail>
  )
}

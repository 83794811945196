import React, { ReactElement } from "react"

interface Props {
  src: string
  alt?: string
  className?: string
}

const Chrome = ({ src, className = "", alt = "" }: Props): ReactElement => {
  return (
    <div
      className={`border border-grey-900 rounded lg:rounded-t-md overflow-hidden ${className}`}
    >
      <div className="bg-grey-700 p-4 py-1.5 lg:py-2.5 flex">
        <div className="w-2 lg:w-3 h-2 lg:h-3 bg-red rounded-full mr-1.5 lg:mr-2" />
        <div className="w-2 lg:w-3 h-2 lg:h-3 bg-yellow rounded-full mr-1.5 lg:mr-2" />
        <div className="w-2 lg:w-3 h-2 lg:h-3 bg-green rounded-full" />
      </div>
      <img src={src} alt={alt} className="w-full h-auto" />
    </div>
  )
}
export default Chrome

import React, { ReactElement } from "react"

interface Props {
  to: string
  label?: string
}

const MoreLink = ({ to, label = "Visit Website" }: Props): ReactElement => {
  return (
    <a href={to} target="_blank" rel="noreferrer" className="more-link">
      {label}
    </a>
  )
}
export default MoreLink

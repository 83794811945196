import React, { ReactElement } from "react"
import { Image } from "../../types/images"
import ImageWithChrome from "./ImageWithChrome"
import ImageWithoutChrome from "./ImageWithoutChrome"

interface Props {
  img: Image
  className?: string
}

const components = {
  Chrome: ImageWithChrome,
  Img: ImageWithoutChrome,
}

const WorkImage = ({ img, className = "" }: Props): ReactElement => {
  const ImageComponent = components[img.chrome ? "Chrome" : "Img"]
  const src = `https://res.cloudinary.com/justinpmartin/image/upload/c_scale,w_1400/v1608772700/portfolio/${img.src}`

  return (
    <ImageComponent
      src={src}
      alt={img.alt}
      className={`w-full h-auto mx-auto my-4 lg:my-8 ${className}`}
    />
  )
}
export default WorkImage

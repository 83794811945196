import React, { ReactElement } from "react"

interface Props {
  src: string
  className?: string
  alt?: string
}

const ImageWithoutChrome = ({
  src,
  className = "",
  alt = "",
}: Props): ReactElement => {
  return <img src={src} alt={alt} className={className} />
}

export default ImageWithoutChrome
